<!-- anchor-sign -->
<template>
  <div class="anchor-sign">
    <!-- 查询表单 -->
    <div class="anchor-sign-form">
      <el-form
        :model="model"
        inline
        size="small"
        label-width="70px"
        @submit.native.prevent
      >
        <el-form-item label="签约日期:">
          <el-date-picker
            v-model="model.signTimeRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="请选择"
            end-placeholder="请选择"
          />
        </el-form-item>

        <el-form-item label="直播平台:">
          <live-platform v-model="model.platformId" />
        </el-form-item>

        <el-form-item label="游戏名称:">
          <game-name v-model="model.gameId" />
        </el-form-item>

        <el-form-item label="支付方式:">
          <payment-type v-model="model.payment" />
        </el-form-item>

        <el-form-item label="是否挂码:">
          <qrcode-type v-model="model.qrcode" />
        </el-form-item>

        <el-form-item label="合作状态:">
          <cooperation-status v-model="model.status" />
        </el-form-item>

        <el-form-item label="搜索:">
          <el-input
            v-model.trim="model.keyword"
            placeholder="主播名称、直播间号、商务"
            clearable
            @keyup.native.enter="handleFormSearchClick"
          />
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="searching"
            @click="handleFormSearchClick"
            >查询
          </el-button>

          <el-button icon="el-icon-refresh" @click="handleFormResetClick"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 操作栏 -->
    <div class="anchor-sign-operate">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="handleOperateAddClick"
        >新增
      </el-button>

      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="!removeIds.length"
        @click="handleOperateRemoveClick"
        >删除
      </el-button>
    </div>

    <!-- 数据表格 -->
    <div class="anchor-sign-table">
      <el-table
        v-loading="loading"
        ref="table"
        :data="signs"
        size="small"
        @selection-change="handleTableSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column label="主播名称" prop="anchorName" />

        <el-table-column label="直播平台" prop="platformName" />

        <el-table-column label="游戏名称" prop="gameName" />

        <el-table-column label="签约天数" prop="days" />

        <el-table-column label="支付方式">
          <template v-slot="{ row: { payment } }">
            <div>
              {{ payment | paymentTypeText }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="价位">
          <template v-slot="{ row }">
            <div>
              {{ row | priceText }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="申请预算(元)" prop="budget" />

        <el-table-column label="是否挂码">
          <template v-slot="{ row: { qrcode } }">
            <div>
              {{ qrcode | qrcodeTypeText }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态">
          <template v-slot="{ row: { status } }">
            <el-tag :type="status | cooperationStatusTagType" size="mini">
              {{ status | cooperationStatusText }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="创建时间" prop="createTime" width="180px" />

        <el-table-column label="操作" width="125px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                v-if="
                  row.status === cooperationStatusEnum.signed &&
                  (currentUserHasFullPermissions ||
                    row.userId === currentUserId)
                "
                icon="el-icon-edit"
                circle
                size="mini"
                @click="handleTableRowEditClick(row)"
              />

              <el-button
                plain
                type="primary"
                icon="el-icon-collection"
                circle
                size="mini"
                @click="handleTableRowRemarkClick(row)"
              />

              <el-button
                v-if="
                  row.status === cooperationStatusEnum.signed &&
                  (currentUserHasFullPermissions ||
                    row.userId === currentUserId)
                "
                plain
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="handleTableRowRemoveClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="anchor-sign-pagination">
      <el-pagination
        :total="total"
        :current-page="pageNo"
        :page-sizes="pageSizes"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageNoChange"
        @size-change="handlePageSizeChange"
      />
    </div>

    <!-- 新增/编辑签约对话框 -->
    <sign-edit v-model="showEditDialog" @on-ok="handleSignEditDialogOkClick" />

    <!-- 删除签约对话框 -->
    <sign-remove
      v-model="showRemoveDialog"
      :ok-button-loading="removing"
      @on-close="handleSignRemoveDialogClose"
      @on-ok="handleSignRemoveDialogOkClick"
    />

    <!-- 签约备注抽屉 -->
    <sign-remark v-model="showRemarkDrawer" />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import LivePlatform from '../../../components/live-platform';
  import GameName from '../../../components/game-name';
  import PaymentType from '../../../components/payment-type';
  import QrcodeType from '../../../components/qrcode-type';
  import CooperationStatus from '../../../components/cooperation-status';
  import { pageSize, pageSizes } from '../../../config/pagination';
  import recountPageNo from '../../../lib/recount-page-no';
  import paymentType from '../../../enum/payment-type.js';
  import qrcodeType from '../../../enum/qrcode-type.js';
  import cooperationStatus from '../../../enum/cooperation-status.js';
  import { removeSigns } from '../../../api/anchor/sign';

  export default {
    name: 'anchor-sign',
    components: {
      LivePlatform,
      GameName,
      PaymentType,
      QrcodeType,
      CooperationStatus,
      SignEdit: () => import(/* webpackChunkName: 'sign-edit' */ './sign-edit'),
      SignRemove: () =>
        import(
          /* webpackChunkName: 'sign-remove' */ '../../../components/confirm'
        ),
      SignRemark: () =>
        import(/* webpackChunkName: 'sign-remark' */ './sign-remark'),
    },
    filters: {
      // 支付方式文案
      paymentTypeText(val) {
        return paymentType.map[val] || '';
      },
      // 价位文案
      priceText(val) {
        const { payment, priceCPT, priceCPS } = val;
        let paymentPriceText = '';

        switch (payment) {
          case paymentType.enum.cps:
            paymentPriceText = `${priceCPS}%`;
            break;

          case paymentType.enum.cptAndCps:
            paymentPriceText = `${priceCPT}元/天 + ${priceCPS}%`;
            break;

          default:
            paymentPriceText = `${priceCPT}元/天`;
        }

        return paymentPriceText;
      },
      // 是否挂码文案
      qrcodeTypeText(val) {
        return qrcodeType.map[val] || '';
      },
      // 合作状态标签类型
      cooperationStatusTagType(val) {
        const typeMap = {
          [cooperationStatus.enum.signed]: 'info',
          [cooperationStatus.enum.scheduled]: 'primary',
          [cooperationStatus.enum.published]: 'warning',
          [cooperationStatus.enum.completed]: 'success',
        };

        return typeMap[val];
      },
      // 签约状态文案
      cooperationStatusText(val) {
        return cooperationStatus.map[val] || '';
      },
    },
    data() {
      return {
        model: null,
        pageNo: 1,
        pageSize,
        pageSizes,
        operateId: '',
        showEditDialog: false,
        removeIds: [],
        showRemoveDialog: false,
        showRemarkDrawer: false,
        loading: false,
        searching: false,
        removing: false,
      };
    },
    computed: {
      ...mapState('anchor/sign', ['signs', 'total']),
      ...mapGetters(['currentUserId', 'currentUserHasFullPermissions']),

      // 签约状态枚举值
      cooperationStatusEnum() {
        return cooperationStatus.enum;
      },
    },
    created() {
      this.initModel();
    },
    mounted() {
      this.getTableData();
    },
    beforeDestroy() {
      this.mttSignId();
    },
    methods: {
      ...mapMutations('anchor/sign', ['mttSignId']),
      ...mapActions('anchor/sign', ['actSigns']),

      // 初始化模型
      initModel() {
        this.model = {
          signTimeRange: [],
          platformId: '',
          gameId: '',
          payment: '',
          qrcode: '',
          status: '',
          keyword: '',
        };
      },
      // 获取表格数据
      async getTableData() {
        const { signTimeRange } = this.model;
        const [startTime = '', endTime = ''] = signTimeRange || [];

        this.loading = true;

        const success = await this.actSigns({
          ...this.model,
          startTime,
          endTime,
          signTimeRange: undefined,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
        });

        this.loading = false;

        return success;
      },
      // 删除表格数据
      async removeTableData() {
        const ids = this.removeIds;

        if (!ids.length) return;

        this.removing = true;

        const res = await removeSigns(ids);

        this.removing = false;

        return !!res;
      },
      // 表单查询按钮单击
      async handleFormSearchClick() {
        this.pageNo = 1;
        this.searching = true;

        await this.getTableData();

        this.searching = false;
      },
      // 表单重置按钮单击
      handleFormResetClick() {
        this.pageNo = 1;

        this.initModel();
        this.getTableData();
      },
      // 操作栏新增按钮单击
      handleOperateAddClick() {
        this.operateId = '';
        this.showEditDialog = true;
      },
      // 操作栏删除按钮单击
      handleOperateRemoveClick() {
        const removeIds = this.removeIds;
        const matchedSigns = this.signs.filter(({ _id }) =>
          removeIds.includes(_id)
        );

        for (let sign of matchedSigns) {
          const { userId, status } = sign;

          if (
            !this.currentUserHasFullPermissions &&
            userId !== this.currentUserId
          ) {
            this.$message.error('只能删除【自己】的数据');

            return;
          }

          if (status !== cooperationStatus.enum.signed) {
            this.$message.error('只能删除状态为【已签约】的数据');

            return;
          }

          continue;
        }

        this.showRemoveDialog = true;
      },
      // 表格选择项改变
      handleTableSelectionChange(rows) {
        this.removeIds = rows.map(row => row._id);
      },
      // 表格行编辑按钮单击
      handleTableRowEditClick({ _id }) {
        this.operateId = _id;
        this.showEditDialog = true;
      },
      // 表格行备注按钮单击
      handleTableRowRemarkClick({ _id }) {
        this.operateId = _id;
        this.showRemarkDrawer = true;
      },
      // 表格行删除按钮单击
      handleTableRowRemoveClick({ _id }) {
        this.removeIds = [_id];
        this.showRemoveDialog = true;
      },
      // 新增/编辑签约对话框确定按钮执行成功
      handleSignEditDialogOkClick() {
        this.getTableData();
      },
      // 删除对话框关闭
      handleSignRemoveDialogClose() {
        this.removeIds = [];

        this.$refs.table.clearSelection();
      },
      // 删除签约对话框确定按钮单击
      async handleSignRemoveDialogOkClick() {
        const success = await this.removeTableData();

        if (!success) return;

        this.showRemoveDialog = false;
        this.pageNo = recountPageNo(
          this.total,
          this.pageNo,
          this.pageSize,
          this.removeIds.length
        );

        this.getTableData();
      },
      // 分页器页码改变
      handlePageNoChange(pageNo) {
        this.pageNo = pageNo;

        this.getTableData();
      },
      // 分页器条目数改变
      handlePageSizeChange(pageSize) {
        this.pageSize = pageSize;

        this.getTableData();
      },
    },
    watch: {
      operateId(n, o) {
        this.mttSignId(n);
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-sign {
    // 查询表单
    .anchor-sign-form {
      .el-form-item {
        margin-right: 70px;
      }

      .el-input,
      .el-select,
      .el-date-editor {
        width: 220px;
      }
    }

    // 数据表格
    .anchor-sign-table {
      margin-top: 20px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }

    // 分页器
    .anchor-sign-pagination {
      margin-top: 20px;
    }
  }
</style>
