<!-- payment-type -->
<template>
  <el-select
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="item in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    />
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import paymentType from '../../enum/payment-type';

  export default {
    name: 'payment-type',
    mixins: [select],
    mounted() {
      this.getPaymentTypes();
    },
    methods: {
      getPaymentTypes() {
        const { map } = paymentType;

        this.list = Object.entries(map).map(([value, key]) => ({
          [this.labelKey]: key,
          [this.valueKey]: +value,
        }));
      },
    },
  };
</script>
